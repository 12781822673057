'use client';
import 'monday-sdk-js';
import { Loader } from 'monday-ui-react-core';

export default function Loading() {
	return (
		<div className='w-full flex items-center justify-center h-full'>
			<Loader size={Loader.sizes.MEDIUM} color={Loader.colors.PRIMARY}/>
		</div>
	);
}